.id-title-bar-linked {
    :first-child::before {
        border-left-color: $quaternary-lighter;
    }
}

.id-title-bar-card {
    // box-shadow: -3px 0rem 3px -3px rgba(47, 55, 61, 0.3), 3px 0rem 3px -3px rgba(60, 64, 67, .15);

    &.collapsed {
        // box-shadow: -3px 3px 3px -3px rgba(47, 55, 61, 0.3), 3px 3px 3px -3px rgba(60, 64, 67, .15);
    }

    .id-collapse {

        :hover {
            color: $tertiary-dark;
        }
    }


    .id-title-bar {

        .id-title-bar-radius {
            background-color: $tertiary-light;
        }

    }
}

.id-title-bar-header {
    background-color: $quaternary-lighter-2;
}

.id-title-bar-content {
    border-left-color: $quaternary-light;
    border-right-color: $quaternary-light;
    border-bottom-color: $quaternary-light;
    // box-shadow: -3px -3px 3px -3px rgba(47, 55, 61, 0.3), 3px 3px 3px -3px rgba(60, 64, 67, .15);
    background-color: $quaternary-lighter-2;
}