.title-user {
    border-top-color: $titleUser;
}

.title-file-share {
    border-top-color : $titleFileShare;
}

.title-exchange {
    border-top-color: $titleExchange;
}

.title-share-point {
    border-top-color: $titleSharePoint;
}

.title-share-point-icon {
    color: $titleSharePoint !important;
}

.title-teams {
    border-top-color: $titleTeams;
}

.title-teams-icon {
    color: $titleTeams !important;
}

.title-yammer {
    border-top-color: $titleYammer;
}

.title-yammer-icon {
    color: $titleYammer !important;
}

.title-one-drive {
    border-top-color: $titleOneDrive;
}

.title-azure-ad {
    border-top-color: $titleAzureAd;
}

.title-zimbra-mailing {
    border-top-color: $titleZimbraMailing;
}

.title-drop-box {
    border-top-color: $titleDropBox;
}

.title-box {
    border-top-color: $titleBox;
}

.title-slack {
    border-top-color: $titleSlack;
}

.title-open-text {
    border-top-color: $titleOpenText;
}

.title-in-webo {
    border-top-color: $titleInWebo;
}

.title-sap {
    border-top-color: $titleSap;
}

.title-idecsi {
    border-top-color: $titleIdecsi;
}

.title-provider {
    border-top-color: $titleDefaultProvider;
}

.title-deleted {
    border-top-color: $titleDisabled;
}

.title-warning {
    border-top-color: $titleWarning;
}