
.timeline-container {
    position: relative;
    overflow: hidden;
}

.timeline-container:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0.5rem;
    width: 0.1rem;
    height: 100%;
    background: $primary;
    z-index: 1
}

.timeline-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    justify-content: space-between;
    clear: both;
}

.timeline-marker {
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
    border: 0.05rem solid $primary;
    background: $tertiary-light;
    margin-top: 0.3rem;
    margin-left: 0.2rem;
    z-index: 9999
}

.timeline-content {
    width: 95%;
    padding: 0 0.4rem;
}

.timeline-content h3 {
    margin-top: 0.4rem;
    margin-bottom: 0.3rem;
    font: 1rem $first-font;
    font-weight: 500
}

.timeline-content p {
    font: 0.9rem $first-font;
    line-height: 1.5em;
    margin-bottom: 0.5rem;
    text-align: justify;
}