.profile-validation {
    box-shadow: -3px 3px 3px -3px rgba(47, 55, 61, 0.3), 3px 3px 3px -3px rgba(60, 64, 67, .15);

    .id-validation-subtitle {
        color: $quaternary-darker;
    }

    .id-validation-ok {
        background-color: $validateButtonBackgroundColor;
        color: $validateButtonColor;
        &:hover {
            background-color: $validateButtonBackgroundColorHover;
            border-color: $validateButtonColorBorder;
        }
        &.with-alert {
            background-color: $validateButtonBackgroundColorWithAlert;
            color:  $validateButtonColorWithAlert;
            &:hover {
                background-color: $validateButtonBackgroundColorWithAlertHover;
                border-color: $validateButtonColorWithAlertBorder;
            }
        }
    }

    .id-validation-help {
        background-color: $helpButtonBackgroundColor;
        color: $helpButtonColor;
        &:hover {
            background-color: $helpButtonBackgroundColorHover;
            border-color: $helpButtonColorBorder;
        }
    }

    .id-validation-collapse-all {
        background-color: $collapseAllButtonBackgroundColor;
        color: $collapseAllButtonColor;
        &:hover {
            background-color: $collapseAllButtonBackgroundColorHover;
            border-color: $collapseAllButtonColorBorder;
        }
    }
}