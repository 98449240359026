.delegate-configuration-box {
    .delegate-details {
        .delegate-mailbox {
            &:after {
                border-right-color: $quaternary-dark;
            }
        }

        .delegate-calendar {
            &:after {
                border-right-color: $quaternary-dark;
            }
        }

        .delegate-instant-messaging {
            &:after {
                border-right-color: $quaternary-dark;
            }
        }
        .not-enabled {
            color: $quaternary-dark-1;
        }


    }
}