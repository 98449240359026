.profile-alert-base-modal {
    .id-header {
        background-color: $quaternary;
        border-color: $quaternary-dark;
        margin-left: 0px;
        margin-right: 0px;
    }

    svg.fa-pencil-alt {
        color: $color-remediate;
    }

    svg.fa-exclamation-triangle {
        color: $color-alerting;
    }
}