#toast-container>div {
    opacity: 1;
}


.toast {
    font-size: initial !important;
    border: initial !important;
    backdrop-filter: blur(0) !important;
}

/* Responsive Design */
@media all and (max-width: 240px) {
    .toast {
        max-width: 96vw !important;
        width: 96vw !important;
    }
}

@media all and (min-width: 241px) and (max-width: 480px) {
    .toast {
        max-width: 90vw !important;
        width: 90vw !important;
    }
}

@media all and (min-width: 481px) and (max-width: 768px) {
    .toast {
        max-width: 80vw !important;
        width: 80vw !important;
    }
}

@media all and (min-width: 769px) and (max-width: 1024px) {
    .toast {
        max-width: 70vw !important;
        width: 70vw !important;
    }
}

@media all and (min-width: 1025px) and (max-width: 1600px) {
    .toast {
        max-width: 70vw !important;
        width: 70vw !important;
    }
}

@media all and (min-width: 1601px) {
    .toast {
        max-width: 60vw !important;
        width: 60vw !important;
    }
}

.toast-success {
    background-color: $toast-success-color !important;
}

.toast-error {
    background-color: $toast-error-color !important;
}

.toast-info {
    background-color: $toast-info-color !important;
}

.toast-warning {
    background-color: $toast-warning-color !important;
}