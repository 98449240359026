.profile-page {
    .id-validation-header {
        color: $validation-sub-title-color;
        background: linear-gradient(90deg, $validation-header-1 0%, $validation-header-2 51%, $validation-header-3 100%);

        .id-photo {
            background-color: $lighter;
            box-shadow: 0px 1px 5px $validation-shadow;
            object-fit: cover;
        }

        .id-title {

            .id-validation-title {
                color: $validation-title-color;
            }
        }
    }

    .sub-header ul li a .id-warning-activity {
        color: $warn-high-level;
    }

    .id-profile-end {
        :first-child::before {
            border-left-color: $quaternary-lighter;
        }
    }
}