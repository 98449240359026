.short-title-bar {
    .id-title-bar-card {
        background-color: $quaternary-lighter-1;
        &.id-title-bar-card-unset {
            background-color: unset !important;
        }

        .id-title-bar {
            :hover {
                color: $tertiary-dark;
            }
            .id-collapse {
                :hover {
                    color: $tertiary-dark;
                }
            }

        }
    }

    .id-title-bar-content {
        border-bottom-color: $quaternary-light;
        background-color: $lighter;
    }
}