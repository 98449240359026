    .id-page-container {
        color: $color;
        .id-content {
            .id-link {
                color: $primary-lighter;

                &:focus {
                    color: $tertiary;
                }

                &:hover {
                    border-bottom-color: $tertiary-dark;
                    color: $tertiary-dark;
                }
            }
        }
    }