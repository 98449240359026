.share-point-site-container {

    .id-one-page {
        color: $primary;

        a {
            :hover {
                color: $tertiary-dark;
            }
        }

    }
    
    .id-col-title {
        color: $primary;
    }

    .id-col-description {
        color: $quaternary-darker-2;
    }


    .loaded {
        border-top-color : $quinary;
    }

    .id-button {

        :hover {
            color: $tertiary-dark;
        }

    }

    .provider-type {
        color: $primary;
    }
}