.user-list {
  .id-blocksearch {
    .id-blocksearch-header {
      background-color: $tertiary-light;
      .id-list-search {
        input {
          font-family: $first-font;
          background: $lighter url($path-to-pictos + "loupe_picto_hover.svg") no-repeat 250px;
        }
      }
    }
  }
  .users {
    background-color: $lighter;
  }
}
