@import 'ngx-toastr/toastr';
@import "./utilities/toaster";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
/* -------begin customization-------- */
$theme-colors: (primary: $primary,
    secondary: $secondary,
    success: $quinary,
    info: $tertiary,
    warning: $secondary-light,
    danger: $secondary,
    light: $quaternary-dark,
    dark: $quaternary-darker,
    primaryDarker: $primary-darker-1);

$link-hover-color: $tertiary;

/* -------end customization-------- */
@import "bootstrap/scss/bootstrap-grid";
@import "bootstrap/scss/bootstrap";
@import "bootstrap/scss/_mixins";

body {
    font-family: $second-font;
    font-size: 0.9rem;
    background-color: $background-color;

    h1 {
        font-size: 1.6rem;
    }

    h2 {
        font-size: 1.4rem;
    }

    h3 {
        font-size: 1.2rem;
    }

    h4 {
        font-size: 1rem;
    }

    a {
        :hover {
            border: none;
            color: $tertiary-dark;
        }

        :focus {
            border: none;
        }
    }

    .col-centered {
        float: none;
        margin: 0 auto;
    }


    .btn {
        border-radius: 1rem;
        font-size: 0.8rem;
    }

    .btn-dark {
        @include button-variant($primary, $primary, $primary);
    }

    .btn-help {
        @include button-variant($helpButtonBackgroundColor, $helpButtonBackgroundColor, $helpButtonBackgroundColor);
    }

    .btn-red {
        @include button-variant($secondary, $secondary, $secondary);
    }

    .btn-warn {
        @include button-variant($senary, $senary, $senary);
        color: $lighter;

        &:hover {
            color: $lighter;
        }
    }


    .btn-outline-alert {
        border-radius: 1rem;
        font-size: 0.8rem;
        font-weight: 500;
        color: $secondary-light-1;
        background-color: $quaternary-lighter-1;
        border-color: $quaternary-lighter-1;

        &:hover {
            background-color: $secondary;
            color: $lighter;
        }
    }


    .btn-outline-alert-dark {
        background-color: unset;
        color: $secondary;
        border-color: $secondary;

        &:hover {
            background-color: $secondary;
            color: $lighter;
        }
    }

    .btn-outline-details {
        border-radius: 1rem;
        font-size: 0.8rem;
        color: $quaternary-darker;
        border-width: 0px;
        background-color: $quaternary-lighter-1;

        :hover {
            color: $primary-darker;
            background-color: $quaternary-dark;
        }
    }

    .disabled {
        color: $quaternary-dark;
    }

    .btn-disabled {
        border-color: $quaternary-dark !important;
        color: $quaternary-dark !important;
        background-color: $quaternary-darker-1 !important;

        :hover {
            color: $quaternary-dark !important;
            background-color: $quaternary-darker-1 !important;
        }
    }


    .has-alert {
        background-color: $secondary-lighter !important;
    }

    .has-alert-recently-closed {
        background-color: $brown-lighter !important;
    }

    .succeeded {
        color: $quinary-darker
    }

    .failed {
        color: $secondary-dark
    }

    .id-modal {
        max-width: 100vw; //BUG modal is bigger than the screen !!

        .modal-content {
            background-color: $primary;
            border-radius: 0.6rem;

            .full-screen {
                height: 100%;
                width: 100%;
                position: fixed;
                z-index: 10000;
                top: 0;
                left: 0;
                overflow-x: hidden;
                padding: 0;
                margin: 0;
                background-color: $lighter;

                .modal-header {
                    border-top-right-radius: 0rem;
                    border-top-left-radius: 0rem;
                }

                .modal-body {
                    // max-height: none;
                    max-height: calc(100% - 8.3rem);
                }

                .modal-footer {
                    border-bottom-right-radius: 0rem;
                    border-bottom-left-radius: 0rem;
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                }

            }

            .modal-header {
                border: 0px !important;
                background-color: $primary;
                color: $lighter;
                padding: 0.9rem 0.9rem;
                border-top-right-radius: 0.6rem;
                border-top-left-radius: 0.6rem;

                .modal-title {
                    text-transform: uppercase;
                }
            }

            .modal-body {
                max-height: 60vh;
                background-color: $lighter;
                padding: 0rem;
                overflow: auto;
                &.no-overflow {//https://github.com/twbs/bootstrap/issues/1078
                    overflow: inherit;   
                }
            }

            .modal-footer {
                border-top: 0.01rem solid $quaternary-dark;
                background-color: $lighter;
                padding: 0.7rem !important;
                border-bottom-right-radius: 0.6rem;
                border-bottom-left-radius: 0.6rem;
            }
        }
    }

    .id-table tbody tr td {
        border-top: 0px;
    }

    .id-table {
        tr {
            &.sensitivity-label {
                background-color: $sensitivity-label-background-dark;
            }
            &.sensitivity-label:nth-of-type(odd) {
                background-color: $sensitivity-label-background;
            }
        }
    }

    .id-table tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.01);
    }

    // .collected-resources-loading {

    //     .id-provider-picture {
    //         margin-left: 0.3rem;
    //         margin-top: 0.2rem;
    //     }

    // }
    .module {
        margin-left: 0.5rem;
        margin-right: 0.5rem;

        .module-container {
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            border-radius: 6px;
            background-color: $lighter;
            margin-top: 2rem;
            padding: 0.25rem;
        }
    }

    .wrapped-container {
        width: 100%;

        .wrapped {
            /* wrap long urls */
            white-space: pre;
            /* CSS 2.0 */
            white-space: pre-wrap;
            /* CSS 2.1 */
            white-space: pre-line;
            /* CSS 3.0 */
            white-space: -pre-wrap;
            /* Opera 4-6 */
            white-space: -o-pre-wrap;
            /* Opera 7 */
            white-space: -moz-pre-wrap;
            /* Mozilla */
            white-space: -hp-pre-wrap;
            /* HP Printers */
            word-wrap: break-word;
            /* IE 5+ */
        }
    }
}

@import "./utilities/loading";
@import "./utilities/guided-tour";
@import "./utilities/timeline";
@import "./provider";

@import "./components/profile/providers/microsoft/azure-ad/azure-ad-permission-grants-modal/azure-ad-permission-grants-modal.component";
@import "./components/profile/providers/microsoft/exchange/exchange-permission/exchange-permission.component";
@import "./components/profile/providers/microsoft/exchange/exchange-inbox-rule/exchange-inbox-rule.component";
@import "./components/profile/providers/microsoft/exchange/exchange-rule-config/exchange-rule-config.component";
@import "./components/profile/providers/microsoft/microsoft-profile/microsoft-profile.component";
@import "./components/profile/providers/microsoft/share-point/share-point-permission/share-point-permission.component";
@import "./components/profile/providers/microsoft/share-point/share-point-resource-has-sensitivity-label/share-point-resource-has-sensitivity-label.component";
@import "./components/profile/providers/microsoft/share-point/share-point-external-permissions-detail/share-point-external-permissions-detail.component";
@import "./components/profile/providers/microsoft/share-point/share-point-administrators/share-point-administrators.component";
@import "./components/profile/providers/microsoft/share-point/share-point-o365-group/share-point-o365-group.component";
@import "./components/profile/providers/microsoft/share-point/share-point-o365-group-modal/share-point-o365-group-modal.component";
@import "./components/profile/providers/microsoft/share-point/share-point-visibility/share-point-visibility.component";
@import "./components/profile/providers/microsoft/share-point/share-point-group/share-point-group.component.scss";
@import "./components/profile/providers/microsoft/share-point/share-point-site-container/share-point-site-container.component.scss";
@import "./components/profile/rules-computed-delegate/rules-computed-delegate-details-modal/rules-computed-delegate-details-modal.component";
@import "./components/profile/shared/delegate-configuration-box/delegate-configuration-box.component";
@import "./components/profile/shared/delegate-device/delegate-device.component";
@import "./components/profile/shared/modals/profile-alert-base-modal/profile-alert-base-modal.component";
@import "./components/profile/shared/nav-bar-warning-counter/warning-container.component";
@import "./components/profile/shared/profile-card/profile-card.component";
@import "./components/profile/shared/profile-information/profile-information.component";
@import "./components/profile/shared/profile-page/profile-page.component";
@import "./components/profile/shared/profile-validation/profile-validation.component";
@import "./components/profile/shared/resource-actions/resource-actions.component";
@import "./components/profile/shared/resource-container/resource-container.component";
@import "./components/profile/shared/resource-last-access/resource-last-access.component";
@import "./components/profile/shared/warning-container/warning-container.component";
@import "./components/profile/user/device/device.component";
@import "./components/profile/user/origin/origin.component";
@import "./components/profile/user/user-container/user-container.component";
@import "./components/profile/user/usual-countries/usual-countries.component";
@import "./components/shared/badge-icon/badge-icon.component";
@import "./components/shared/collapse-container/collapse-container.component";
@import "./components/shared/modal/base-modal/base-modal.component";
@import "./components/shared/modal/information-modal/information-modal.component";
@import "./components/shared/page/nav-bar-base/nav-bar-base.component";
@import "./components/shared/page/header/header.component";
@import "./components/shared/page/page-container/page-container.component";
@import "./components/shared/sub-title/sub-title.component";
@import "./components/shared/title-bar/title-bar.component";
@import "./components/shared/short-title-bar/short-title-bar.component";
@import "./components/users/user-list/user-list.component";