$path-to-pictos : '/assets/pictos/';

//Fonts
$first-font : 'Rajdhani', sans-serif;
$second-font: 'Raleway', sans-serif;

$background-color: #fff;
$color: #000;

//Colors 
$lighter: #fff;
$lighter-1: #ddd;

$primary : #1b1f2d;
$primary-dark : #100909;
$primary-darker : #000000;
$primary-light : #111111;
$primary-lighter : #292f43;
$primary-darker-1 :#11284d;

$secondary: #aa0000;
$secondary-dark: #8d0202;
$secondary-light: #e18989;
$secondary-light-1: #c56262;
$secondary-lighter: #f9e7e7;

$tertiary: #c8c2a1;
$tertiary-dark: #9c9364;
$tertiary-darker: #f9c434;
$tertiary-darker-1: #777252;
$tertiary-light: #ddd9c3;
$tertiary-lighter-1: #f9f8f3;
$tertiary-lighter: #c1c1c1;

$quaternary: #eeeeee;
$quaternary-dark: #cdd1d3;
$quaternary-dark-1: #ececec;
$quaternary-darker: #595959;
$quaternary-darker-1: #a9a9a9;
$quaternary-darker-2: #939393;
$quaternary-light: #e7eaec;
$quaternary-lighter: #dfdfdf;
$quaternary-lighter-1: #f7f7f7;
$quaternary-lighter-2: #f9f9f9;

$quinary : #00aa7c;
$quinary-dark:  #008460;
$quinary-darker : #00887c;
$quinary-darker-1:  #007757;

$senary : #f98d34;
$senary-dark : #e77e28;
$senary-darker : #d37122;

$septenary: #2c679e;
$septenary-dark: #2b5c8a;
$septenary-darker: #173958;

$validation-header-1: #11384d;
$validation-header-2: #2c679e;
$validation-header-3: #008460;
$validation-sub-title-color: $lighter;
$validation-title-color: $lighter;
$validation-shadow: #353535;

$toast-success-color: #51A351;
$toast-error-color: #BD362F;
$toast-info-color: #2F96B4;
$toast-warning-color: #F89406;

$warn-high-level: #cc0000;
$warn-high-level-dark: #bf0000;
$warn-high-level-lighter: #fcf5f5;

$warn-medium-level: #cc7400;
$warn-medium-level-dark: #bf4100;
$warn-medium-level-lighter: #fcf9f5;

$warn-low-level: #2c76c0;
$warn-low-level-dark: #1d548a;
$warn-low-level-lighter: #fff;

$sensitivity-label-background: #f6fafe;
$sensitivity-label-background-dark: #e7f3ff;

$titleDisabled:#353535;
$titleUser:#11284d;
$titleFileShare:#eecd25;
$titleExchange:#045CAE;
$titleSharePoint:#03787c;
$titleTeams:#5558af;
$titleYammer:#2279c0;
$titleOneDrive:#28A8EA;
$titleAzureAd:#0078d7;
$titleZimbraMailing:#eb5d2b;
$titleDefaultProvider:#11284d;
$titleDropBox: $titleDefaultProvider;
$titleBox: $titleDefaultProvider;
$titleSlack: $titleDefaultProvider;
$titleOpenText: $titleDefaultProvider;
$titleInWebo: $titleDefaultProvider;
$titleSap: $titleDefaultProvider;
$titleIdecsi: $titleDefaultProvider;
$titleWarning: $warn-high-level;


$helpButtonBackgroundColor: $septenary;
$helpButtonBackgroundColorHover: $septenary-dark;
$helpButtonColor: $lighter;
$helpButtonColorBorder: $septenary-darker;

$validateButtonBackgroundColor : $quinary;
$validateButtonBackgroundColorHover: $quinary-dark;
$validateButtonColor: $lighter;
$validateButtonColorBorder: $quinary-darker-1;

$validateButtonBackgroundColorWithAlert: $senary;
$validateButtonBackgroundColorWithAlertHover: $senary-dark;
$validateButtonColorWithAlert: $lighter;
$validateButtonColorWithAlertBorder: $senary-darker;

$collapseAllButtonBackgroundColor: $quaternary;
$collapseAllButtonBackgroundColorHover: $quaternary-dark;
$collapseAllButtonColor: $quaternary-darker;
$collapseAllButtonColorBorder: $quaternary-darker;


$get-started-heading-color: $primary;
$get-started-container-mobile: $lighter;
$get-started-container-mobile-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
$get-started-container-mobile-carousel-caption: $primary-darker-1;
$get-started-container-mobile-carousel-indicators: #2993E0;
$get-started-container-mobile-card-shadow: 0px 0px 8px rgba(0, 0, 0, 0.07);



$warn-color: $warn-high-level;
$warn-group-member-color: $warn-high-level;
$warn-font-size: 1rem;
$warn-font-weight: 600;

$color-alerting: #cc0000;
$color-remediate: #51A351;

$nav-bar-background-color: #F4F4F4;
$nav-bar-text: #2B3146;

$profile-card-brown: #986d1c;
$brown-lighter: #9d8d71;
