.sharepoint-permission {
    .warn-account {
        color: $warn-color;
        font-size: $warn-font-size;
        font-weight: $warn-font-weight;
    }
    .warn-sensitivity-label {
        font-size: $warn-font-size;
    }

}