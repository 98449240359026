.bagde-icon {
    &.id-bagde-icon-radius {
        background-color: $tertiary-light;
        color: $tertiary-darker-1;
    }

    &.id-bagde-anonymous {
        background-color: #f1f6f1;
        color: #2b7e2b;
        border: 1px solid #2b7e2b;
    }

    &.id-bagde-whole-company {
        background-color: #f0f5f8;
        color: #004e8c;
        border: 1px solid #004e8c;
    }

    &.id-bagde-origins {
        background-color: #f1f6f1;
        color: #2b7e2b;
        border: 1px solid #2b7e2b;
    }

    &.id-bagde-access-modes {
        background-color: #f8f7f0;
        color: #a2981a;
        border: 1px solid #a2981a;
    }

    &.id-bagde-devices {
        background-color: #f0f5f8;
        color: #004e8c;
        border: 1px solid #004e8c;
    }

    &.id-bagde-loading {
        border: 0.1rem solid $quaternary-dark;
        background-color: $quaternary-dark-1;
    }

    &.id-bagde-error {
        border: 0.1rem solid $secondary;
        background-color: white;
        color: $secondary;
    }

    &.id-badge-warning {
        color: $warn-high-level;
        background-color: $warn-high-level-lighter;
        border: 1px solid $warn-high-level;
    }

    &.id-badge-warning-high-level {
        color: $warn-high-level;
        background-color: $warn-high-level-lighter;
        border: 1px solid $warn-high-level;
    }

    &.id-badge-warning-medium-level {
        color: $warn-medium-level;
        background-color: $warn-medium-level-lighter;
        border: 1px solid $warn-medium-level;
    }

    &.id-badge-share-point-list {
        //color: $titleSharePoint;
        background-color: $quaternary-lighter-2;
        border: 1px solid $quaternary-lighter-2;
        border: none;
    }
}
