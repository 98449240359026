.nav-bar-base {
    font-family: $first-font;
    box-shadow: 0px 1px 5px $quaternary-darker-1;



    .btn-outline-gray {
        @include button-outline-variant($quaternary, lighten($quaternary, 5%), $quaternary, transparent);
    }


}