.introjs-tooltip {
    padding: 0px !important;
    background-color: $quaternary-lighter !important;
    min-width: 18rem !important;
    border-radius: 5px !important;

    @media all and (max-width: 240px) {
        max-width: 22rem !important;
    }

    @media all and (min-width: 241px) and (max-width: 480px) {
        max-width: 22rem !important;
    }

    @media all and (min-width: 481px) and (max-width: 768px) {
        max-width: 28rem !important;
        width: 28rem !important;
    }

    @media all and (min-width: 769px) and (max-width: 1024px) {
        max-width: 30rem !important;
        width: 30rem !important;
    }

    @media all and (min-width: 1025px) and (max-width: 1600px) {
        max-width: 30rem !important;
        width: 30rem !important;
    }

    @media all and (min-width: 1601px) {
        max-width: 30rem !important;
        width: 30rem !important;
    }

}

.introjs-arrow.bottom {
    left: 10px !important;
}

.introjs-arrow.top {
    left: 10px !important;
}

.introjs-tooltiptext {
    margin-top: 0.2rem;
}

.introjs-progress {
    height: 0.2rem !important;
    border-radius: 0px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
    margin-top: 10px !important;
    margin-bottom: 0px !important;
    background-color: $tertiary-lighter-1 !important;

    .introjs-progressbar {
        background-color: $primary-darker !important;
    }
}

.introjs-tooltipbuttons {
    padding: 10px !important;
}

.introjs-buttons {
    margin-top: 0px !important;
}

.introjs-button {
    padding-top: 0.1rem !important;
    padding-right: 0.75rem !important;
    padding-bottom: 0.1rem !important;
    padding-left: 0.75rem !important;
    cursor: pointer;
    color: $lighter !important;
    background-color: $primary !important;
    border-color: $primary !important;
    text-shadow: none !important;
    background-image: none !important;
    font: 1rem $first-font !important;
    margin-top: 0px !important;
}

.introjs-prevbutton:hover {
    border-radius: 1rem !important;
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
    font-size: 1rem !important;
    background-color: $primary-light !important;
}

.introjs-nextbutton:hover {
    border-radius: 1rem !important;
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
    font-size: 1rem !important;
    background-color: $primary-light !important;
}

.introjs-prevbutton {
    border-radius: 1rem !important;
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
    font-size: 1rem !important;
    margin-right: 0.1rem !important;
}

.introjs-nextbutton {
    border-radius: 1rem !important;
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
    font-size: 1rem !important;
    margin-right: 0.1rem !important;
}

.introjs-skipbutton {
    border-radius: 1rem !important;
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
    font-size: 1rem !important;
    float: left;
    background-color: $secondary !important;
    border-color: $secondary !important;

    :hover {
        background-color: $secondary-dark !important;
    }
}

.introjs-skipbutton:hover {
    border-radius: 1rem !important;
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
    font-size: 1rem !important;
    background-color: $secondary-dark !important;
}

.introjs-donebutton:hover {
    border-radius: 1rem !important;
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
    font-size: 1rem !important;
    background-color: $secondary-dark !important;
}


.introjs-donebutton {
    border-radius: 1rem !important;
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
    font-size: 1rem !important;
    float: right!important;
    background-color: $secondary !important;
    border-color: $secondary !important;
    margin-bottom: 10px !important;
    :hover {
        background-color: $secondary-dark !important;
    }
}

//fix modal blank highlight issue
.introjs-fixParent,
#mydiv.introjs-fixParent {
    position: absolute !important;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        position: relative !important;
    }

}

.introjs-helperLayer {
    background-color: transparent !important;
    
}

.introjs-disabled {
    opacity: 0.6 !important;
    background-color: $quaternary-darker !important;
    color: $quaternary-dark !important;
    cursor: not-allowed;
}

.introjs-disabled:hover {
    background-color: $quaternary-darker !important;
    color: $quaternary-dark !important;
}

.gd-step {

    .gd-header {
        color: $validation-sub-title-color;
        background: linear-gradient(90deg, $validation-header-1 0%, $validation-header-2 51%, $validation-header-3 100%);
        font-weight: 700;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-bottom: 0.2rem;
        text-align: center;
    }

    .gd-content {
        padding: 0.9rem;
        background-color: $lighter;
        border-top: 0.01rem solid $primary-lighter;
        border-bottom: 0.01rem solid $primary-lighter;

        ul {
            list-style: none;
            /* Remove default bullets */
            font-family: $first-font;
            font-size: 0.9rem;
            padding-left: 0.6rem;

            li::before {
                display: inline-block;
                content: '';
                border-radius: 0.375rem;
                height: 0.6rem;
                width: 0.6rem;
                margin-right: 0.4rem;
                background-color: $tertiary;
                border: 0.05rem solid $primary;
            }
        }
    }


    .gd-bullet {
        background-color: transparent;
        text-transform: initial;
        font-weight: 500;
    }

    .gd-bullet:before {
        padding: 0.4rem;
        content: url('/assets/pictos/guide-tour-header.png')
    }

    .gd-paragraphe {
        font-family: $first-font;
        font-size: 0.9rem;
        text-align: justify;
    }
}