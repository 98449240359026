.profile-information {
    .linked {
        position: relative;
        :first-child::before {
            border-color: $quaternary-lighter;
        }
    }

    .id-information-card {
        box-shadow: -3px 3px 3px -3px rgba(47, 55, 61, 0.3), 3px 3px 3px -3px rgba(60,64,67,.15);
    }
}