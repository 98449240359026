.profile-card {
    border-color: $quaternary-dark;
    background-color: $lighter;

    .profile-card-summary {

        .profile-card-subtitle {
            color: $quaternary-darker;
        }

        .profile-card-status {
            border-left-color: $quaternary-dark;
            border-bottom-color: $quaternary-dark;
        }

        .profile-card-status-has-alert {
            background-color: $secondary-dark;
            border-color: $secondary-dark;
            color: $lighter;
        }

        .profile-card-status-alert-recently-closed {
            background-color: $profile-card-brown;
            border-color: $profile-card-brown;
            color: $lighter;
        }
    }

}

.profile-card-brown {
    border-color: $profile-card-brown;
}

.profile-card-red {
    border-color: $secondary-dark;
}

.profile-card-warning-high-level {
    border: 1px $warn-high-level solid;
    background-color: $warn-high-level-lighter;
}

.profile-card-warning-medium-level {
    border: 1px $warn-medium-level solid;
    background-color: $warn-medium-level-lighter;
}

.profile-card-warning-low-level {
    border: 1px $warn-low-level solid;
    background-color: $warn-low-level-lighter;
}