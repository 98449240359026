.get-started {

    .headings {
        .subtitle {
            color: $get-started-heading-color;
        }

        &.headings-get-started {

            h1 {
                color: $get-started-heading-color;
            }

        }
    }

    .get-started-container-mobile-container {
        box-shadow: $get-started-container-mobile-shadow;
    }

    .get-started-container-mobile {
        background-color: $get-started-container-mobile;
        box-shadow: $get-started-container-mobile-shadow;
    }

    .get-started-container-mobile .carousel-caption {
        color: $get-started-container-mobile-carousel-caption;
    }

    .get-started-container-mobile .carousel-indicators li {
        background-color: $get-started-container-mobile-carousel-indicators;
    }

    .card {
        background-color: $get-started-container-mobile;
        box-shadow: $get-started-container-mobile-shadow;
    }


    //------------------------------------//
    //    Responsiveness                  //
    //------------------------------------//

    @media screen and (max-width: 991px) {
        .card {
            box-shadow: $get-started-container-mobile-card-shadow;
        }
    }
}

.collected-resources-loading {

    .helper {

        &.helper-get-started {
            color: $primary;

        }

        .helper-info {
            color: $primary;
        }

        .arrow {
            fill: $primary;
        }
    }
}