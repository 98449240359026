.collapse-container {

    .id-button {
        color: $primary;

        :hover {
            color: $tertiary-dark;
        }

    }
}