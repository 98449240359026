.resource-last-access {
    .info-container {

        .sensitivity-label {
            color: $warn-color;
            font-size: $warn-font-size;
            font-weight: $warn-font-weight;
        }

    }
}