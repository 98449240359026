@import "./utilities/colors";

.close {
    color: $lighter;

    &.close-margin-left {
        margin-left: 1rem;
    }
}


.accordion {
    .card {
        margin-bottom: 0 !important;
        border-bottom: 1px solid rgba(0, 0, 0, .04) !important;

        .card-header {
            // padding-top: 0;
            padding: 0;

            button {
                padding: 0.2rem !important;
                width: 100% !important;
                border-radius: 0px !important;

                .btn {
                    border-radius: 0px !important;
                    &.focus {
                        box-shadow: none !important;
                    }
                }

                span {
                    float: left !important;
                }

                fa-icon {
                    float: left !important;
                }
            }
        }

        .card-body {
            padding: 1rem;
        }
    }
}